<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              dark
              color="secondary"
              outlined
              @click="$router.back()"
            >
              <v-icon>mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title>Article</v-card-title>
        <v-card-title>
          <v-row no-gutter>
            <v-col cols="12" sm="6" md="8">
              <v-form ref="form" v-model="el.validForm" lazy-validation>
                <div>
                  <v-switch v-model="blog.enabled" label=" Enabled" outlined />
                </div>
                <div>
                  <v-dialog
                    ref="publishDateDialog"
                    v-model="el.publishDateDialog"
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="blog.date"
                        label="Publish date"
                        v-on="on"
                        :rules="[rule.required]"
                        outlined
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="blog.date"
                      @input="el.publishDateDialog = false"
                      scrollable
                    >
                    </v-date-picker>
                  </v-dialog>
                </div>
                <div>
                  <v-text-field
                    v-model="blog.site_title"
                    type="text"
                    label="Site title"
                    outlined
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="blog.meta_description"
                    type="text"
                    label="Meta description"
                    outlined
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="blog.meta_keywords"
                    type="text"
                    label="Meta keywords"
                    outlined
                  />
                </div>
                <div class="mb-5">
                  <v-tabs v-model="el.tabs" grow>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab>Dutch</v-tab>
                    <v-tab>English</v-tab>
                  </v-tabs>
                  <v-divider></v-divider>
                  <v-tabs-items v-model="el.tabs">
                    <v-tab-item :eager="true">
                      <v-card class="pt-4" flat>
                        <div>
                          <v-text-field
                            v-model="blog.title"
                            type="text"
                            label="Title"
                            :rules="[rule.required]"
                            outlined
                          />
                        </div>
                        <div>
                          <v-text-field
                            v-model="blog.title_short"
                            type="text"
                            label="Short title"
                            :rules="[rule.required]"
                            outlined
                          />
                        </div>
                        <div>
                          <html-editor
                            v-model="blog.body"
                            placeholder="Article body"
                            :className="el.bodyError ? 'blog-body-error' : ''"
                          ></html-editor>
                          <v-slide-y-transition>
                            <div
                              v-if="el.bodyError"
                              class="blog-body-error--detail red--text text--lighten-6 mx-3"
                            >
                              Required.
                            </div>
                          </v-slide-y-transition>
                        </div>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item :eager="true">
                      <v-card class="pt-4" flat>
                        <div>
                          <v-text-field
                            v-model="blog.title_en"
                            type="text"
                            label="Title"
                            :rules="[rule.required]"
                            outlined
                          />
                        </div>
                        <div>
                          <v-text-field
                            v-model="blog.title_short_en"
                            type="text"
                            label="Short title"
                            :rules="[rule.required]"
                            outlined
                          />
                        </div>
                        <div>
                          <html-editor
                            v-model="blog.body_en"
                            placeholder="Article body"
                            :className="el.bodyError ? 'blog-body-error' : ''"
                          />
                          <v-slide-y-transition>
                            <div
                              v-if="el.bodyEnError"
                              class="blog-body-error--detail red--text text--lighten-6 mx-3"
                            >
                              Required.
                            </div>
                          </v-slide-y-transition>
                        </div>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </div>

                <v-divider></v-divider>

                <div class="mt-4">
                  <p class="subtitle-1 font-weight-bold">Image</p>
                  <v-row>
                    <v-col>
                      <v-img
                        :src="el.imagePreview"
                        class="mx-auto mb-3"
                        aspect-ratio="1.6"
                      ></v-img>
                      <v-file-input
                        v-model="blog.image"
                        label="Upload image"
                        accept="image/png, image/jpeg, image/webp"
                        prepend-icon="mdi-camera-outline"
                        truncate-length="10"
                        :rules="isCreate ? [rule.required] : []"
                        show-size
                        outlined
                        dense
                      ></v-file-input>
                    </v-col>
                    <v-col>
                      <template v-if="!isCreate">
                        <v-btn
                          @click="el.thumbnailDialog = true"
                          class="mb-4"
                          elevation="2"
                          block
                        >
                          Preview thumbnail
                        </v-btn>
                        <v-btn
                          @click="el.mainThumbnailDialog = true"
                          elevation="2"
                          block
                          >Preview main image</v-btn
                        >
                      </template>
                    </v-col>
                  </v-row>
                </div>

                <v-divider></v-divider>

                <div class="mt-4">
                  <p class="subtitle-1 font-weight-bold">Tags</p>
                  <v-row>
                    <v-col cols="12">
                      <v-combobox
                        v-model="blog.tags"
                        :items="getModels"
                        label="Selected"
                        :return-object="true"
                        :item-text="o => o.name"
                        :rules="[rule.required]"
                        multiple
                        outlined
                        chips
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            @click:close="data.parent.selectItem(data.item)"
                            close-icon="mdi-close"
                            close
                          >
                            {{ data.item.name ? data.item.name : data.item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-col>
            <v-col cols="6" md="4">
              <v-btn
                class="mb-4"
                block
                elevation="2"
                :loading="getBlogPreviewLoading"
                @click="showPreviewBlog(selectedLang)"
              >
                Preview article page
              </v-btn>
              <v-btn
                @click="save"
                :loading="getBlogsLoading"
                color="primary"
                elevation="2"
                block
              >
                {{ isCreate ? "Save" : "Update" }} article
              </v-btn>
              <div v-if="lastUpdated" class="caption mt-2">
                Last updated: {{ lastUpdated }}
              </div>
            </v-col>
          </v-row>
        </v-card-title>
      </base-card>

      <template-preview
        v-if="preview.show"
        :selected-lang="selectedLang"
        v-model="preview.show"
        :src="preview.src"
        @language-select="showPreviewBlog"
      ></template-preview>
      <Lightbox v-model="el.thumbnailDialog" :src="thumbnailSrc"></Lightbox>
      <Lightbox
        v-model="el.mainThumbnailDialog"
        :src="mainThumbnailSrc"
      ></Lightbox>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import rule from "@/shared/rules";
import _ from "lodash";
import moment from "moment";
import store from "@/store";
import Lightbox from "@/components/Lightbox";
import TemplatePreview from "@/components/TemplatePreview";
import HtmlEditor from "@/components/HtmlEditor";
import { LANGUAGE, DEFAULT_LANGUAGE } from "@/shared/constants";

export default {
  metaInfo: {
    title: "Blog"
  },
  components: { HtmlEditor, Lightbox, TemplatePreview },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch("setModels", { model: "tag" });
      next();
    } catch (error) {
      console.error(error);
    }
  },
  data() {
    return {
      rule,
      id: _.get(this.$route, "params.id"),
      blog: {
        enabled: false,
        date: moment().format("YYYY-MM-DD"),
        title: null,
        title_en: null,
        title_short: null,
        title_short_en: null,
        body: null,
        body_en: null,
        image: null,
        tags: null,
        site_title: null,
        meta_keywords: null,
        meta_description: null
      },
      el: {
        validForm: false,
        publishDate: false,
        tabs: null,
        imageTemp: null,
        imagePreview: "https://i.stack.imgur.com/y9DpT.jpg",
        thumbnailDialog: false,
        mainThumbnailDialog: false,
        bodyError: false,
        bodyEnError: false
      },
      preview: {
        show: false,
        src: null
      },
      selectedLang: DEFAULT_LANGUAGE,
      languages: LANGUAGE
    };
  },
  mounted() {
    if (!this.isCreate) {
      const blog = _.cloneDeep(store.getters.getBlog(this.id));
      _.set(this.el, "imagePreview", this.getPreview(_.get(blog, "image")));
      _.set(this.el, "imageTemp", _.get(blog, "image"));
      _.set(blog, "image", null);
      this.blog = blog;
    }
  },
  methods: {
    ...mapActions(["createBlog", "updateBlog", "previewBlog"]),
    getPayload() {
      const payload = new FormData();
      _.forOwn(this.blog, (value, key) => {
        let val = value;

        if (key === "enabled") {
          val = key === "enabled" ? Number(value) : value;
        }

        if (key === "tags") {
          val = JSON.stringify(_.map(value, v => _.get(v, "id", v)));
        }

        payload.append(key, val === null ? "" : val);
      });

      if (!this.isCreate) {
        _.each(["id", "created_at", "updated_at"], key => payload.delete(key));
      }

      return payload;
    },
    async showPreviewBlog(lang) {
      this.selectedLang = lang;
      this.validateBlogBody();

      if (!this.$refs.form.validate() || !this.validBlogBody) {
        store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }

      const payload = this.getPayload();
      this.preview.src = await this.previewBlog({ id: this.id, payload, lang });
      this.preview.show = true;
    },
    save() {
      this.validateBlogBody();

      if (!this.$refs.form.validate() || !this.validBlogBody) {
        store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }

      const payload = this.getPayload();
      this.isCreate
        ? this.createBlog(payload)
        : this.updateBlog({ id: this.id, payload });
    },
    validateBlogBody() {
      this.el.bodyError = !this.htmlToText(this.blog.body || "");
      this.el.bodyEnError = !this.htmlToText(this.blog.body_en || "");
    },
    htmlToText(html) {
      return html.replace(/<[^>]*>?/gm, "");
    },
    getPreview(image) {
      return image
        ? `${process.env.VUE_APP_API_URL}/commands/thumbnail?resource=${image}&width=150&height=150`
        : "https://i.stack.imgur.com/y9DpT.jpg";
    }
  },
  computed: {
    ...mapGetters(["getModels", "getBlogsLoading", "getBlogPreviewLoading"]),
    isCreate() {
      return _.get(this.$route, "meta.kind") === "create";
    },
    validBlogBody() {
      return !this.el.bodyError && !this.el.bodyEnError;
    },
    lastUpdated() {
      if (this.isCreate) {
        return null;
      }

      return moment(_.get(this.blog, "updated_at")).format("YYYY-MM-DD HH:mm");
    },
    thumbnailSrc() {
      return this.el.imageTemp
        ? `${process.env.VUE_APP_API_URL}/commands/thumbnail?resource=${this.el.imageTemp}`
        : null;
    },
    mainThumbnailSrc() {
      return this.el.imageTemp
        ? `${process.env.VUE_APP_API_URL}/commands/thumbnail?resource=${this.el.imageTemp}&width=800&height=800`
        : null;
    }
  }
};
</script>
<style lang="scss" scoped>
.blog-body-error {
  border: 2px solid #ff5252;
  border-radius: 4px;
}

.blog-body-error--detail {
  font-size: 0.75rem;
}
</style>
